import React from 'react';
import PropTypes from 'prop-types';

const Container = ({ contrast, children }) => {
  return (
    <article className={`container${contrast ? ' contrast' : ''}`}>
      {children}
    </article>
  );
};

Container.propTypes = {
  children: PropTypes.node,
};

export default Container;
