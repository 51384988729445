import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from 'components/Layout';
import Container from 'components/Container';

/*

  TODO:
  - article about the differnce between summer and term-time traffic
*/

const IndexPage = () => {
  return (
    <Layout pageName='home'>
      <Helmet>
        <title>
          One Hollingdean: A resident-led campaign to improve where we live
        </title>
        <meta
          name='description'
          content='A resident-led campaign to improve where we live.  Focusing on the introduction of a residental parking scheme in Hollingdean, Brighton'
        />

        <meta property='og:title' content='One Hollingdean' />
        <meta property='og:site_name' content='One Hollingdean' />
        <meta property='og:url' content='https://one-hollingdean.com/' />
        <meta
          property='og:description'
          content='A resident-led campaign to improve where we live.  Focusing on the introduction of a residental parking scheme in Hollingdean, Brighton'
        />
        <meta property='og:type' content='website' />
        <meta
          property='og:image'
          content='https://one-hollingdean.com/og-image.png'
        />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:title' content='One Hollingdean' />
        <meta
          name='twitter:description'
          content='A resident-led campaign to improve where we live.  Focusing on the introduction of a residental parking scheme in Hollingdean, Brighton'
        />
        <meta
          name='twitter:image'
          content='https://one-hollingdean.com/twitter-image.png'
        />
      </Helmet>
      <Container>
        <h1>
          One <br />
          Hollingdean
        </h1>
        <h2>A resident-led campaign to improve where we live</h2>
      </Container>
      <Container contrast={true}>
        <h3>
          During the initial Hollingdean parking consultation (2022), residents
          in the proposed controlled parking area voted:
        </h3>
        <ol className='facts-table'>
          <h4>
            <strong>61.4% </strong>in support of a parking scheme
          </h4>
          <h4>
            <strong>52.3% </strong> wanted a full scheme rather than light-touch
          </h4>
          <h4>
            <strong>60.4% </strong> asked for it to be Monday-to-Sunday
          </h4>
          <h4>
            <strong>£146 </strong> per year for an average family car permit
          </h4>
        </ol>
        <p className='facts-note'>
          <strong>Total response rate: 33.0% (731 out of 2218).</strong>
        </p>
        <p className='facts-note'>
          Voting figures can be verified under Section 27 on the{' '}
          <a
            href='https://democracy.brighton-hove.gov.uk/ieListDocuments.aspx?CId=823&MId=10756&Ver=4'
            target='_blank'
            rel='noreferrer'
          >
            Environment, Transport and Sustainability Committee website
          </a>{' '}
          or by reading{' '}
          <a
            href='https://one-hollingdean.com/2022-initial-consultation-smaller-area-results.pdf'
            target='_blank'
            rel='noreferrer'
          >
            the official report here
          </a>{' '}
          (downloaded to this website 23/09/2022 from source).
        </p>
        <p className='facts-note'>
          Permit prices can be verified on the{' '}
          <a
            href='https://consultations.brighton-hove.gov.uk/parking/hollingdean/'
            target='_blank'
            rel='noreferrer'
          >
            consultation website
          </a>{' '}
          or by reading{' '}
          <a
            href='https://one-hollingdean.com/hollingdean-permit-prices.pdf'
            target='_blank'
            rel='noreferrer'
          >
            the council price list here
          </a>{' '}
          (downloaded to this website 21/04/2023 from source).{' '}
          <strong>
            Resident permit prices in Hollingdean will start from £41
          </strong>
          .
        </p>
        <p className='facts-note'>
          Average family car emissions per year can be found in the{' '}
          <a href='https://www.smmt.co.uk/' target='_blank' rel='noreferrer'>
            SMMT annual reports
          </a>
          .
        </p>
      </Container>
      <Container contrast={true}>
        <h3>
          Have you received a flyer putatively from us through your letterbox or
          under your windscreen wiper?
        </h3>
        <p>
          <u>These are not from One Hollingdean</u>. We do not, and have never,
          used door-to-door marketing in this fashion. Further, we do not agree
          with nor support the contents of these leaflets and believe them to be
          inappropriately alarmist, quoting costs and consequences that are
          simply not true.
        </p>
        <p>
          <strong>
            As a local, resident-led campaign, we <u>strongly support</u> the
            introduction of restricted residents' parking within our
            neighbourhoods under certain considerations.
          </strong>
        </p>
        <p>
          As far as we know, these flyers originate from individuals who are{' '}
          <u>not residents of Hollingdean</u> and do not have local interests at
          heart. These people benefit from being able to park on our streets for
          free. They:
          <ul>
            <li>Store their second and third cars here.</li>
            <li>Store their work vehicles and campervans here.</li>
            <li>
              Use Hollingdean as a stepping point in their commute into the
              city.
            </li>
          </ul>
        </p>
        <p>
          These leaflets are often non-recyclable and dispersed indiscriminately
          across our neighbourhoods. They cause significant litter problems and
          block local drains. Notably, the individuals distributing these have
          never returned to clear up their litter afterwards. Instead, they
          leave it to local residents to clear up after them.
        </p>
        <p>
          <strong>
            We maintain that introducing controlled resident parking in
            Hollingdean will significantly improve the quality of life for our
            residents. It will reduce traffic, reduce pollution (both air and
            noise), and it will make our roads safer.
          </strong>
        </p>
      </Container>

      <Container>
        <h3>
          September 2023:
          <br />A brief update
        </h3>
        <p>
          As many will be aware, the council published the results of
          Hollingdean's most recent consultation ahead of the{' '}
          <a
            href='https://democracy.brighton-hove.gov.uk/ieListDocuments.aspx?CId=1091&MId=11402'
            target='_blank'
            rel='noreferrer'
          >
            Transport & Sustainability Committee meeting
          </a>{' '}
          next week.
        </p>
        <p>
          <strong>
            These results show that support for the proposed scheme design was
            over 40%.
          </strong>
        </p>
        <p>
          We have met with the council and our councillors, who raised
          significant concerns regarding an opposing campaign, which included
          misrepresented data that they both believe may have influenced this
          result.{' '}
          <strong>
            In part, these are the alarmist fliers distributed by non-residents
            that we have discussed before.
          </strong>
        </p>
        <p>
          We also note that in some of the fliers that our residents received
          during the consultations, the publisher claimed to be attending this
          upcoming meeting to speak on their stance to the committee. We note
          that they are not scheduled to do so and, therefore, remain anonymous,
          aside from those individuals caught distributing these fliers on
          residental CCTV.
        </p>
        <p>
          The committee will discuss this situation in detail at the meeting on
          October 3rd, so we will withhold further comment on the results until
          the committee has made a decision.
        </p>
      </Container>

      <Container>
        <h3>
          June 2023:
          <br />
          What happens now?
        </h3>
        <p>
          We had previously understood that the consultation results would be
          prepared and presented to the Transport & Sustainability Committee
          (replacing the now-decommissioned Environment, Transport &
          Sustainability Committee) at their meeting this month. This assurance
          had been seen as a way for the council to start makign amends for the
          delays we have seen throughout this process. However, this will now
          only happen once the newly formed committee finds their feet, most
          likely in October.
        </p>
        <p>
          <strong>
            We now understand that the intention is for the results to be
            presented on the 3rd of October. This significant delay is
            disappointing, but at least it shows that the process is progressing
            - even if it is much slower than many would have liked.
          </strong>
        </p>
        <h4>The process from here</h4>
        <p>
          If support from the latest consultation is sufficient, a Traffic
          Regulation Order (TRO) will be published. The TRO has a 21-day period
          where the general public can comment either in support or objection to
          the proposals; we would expect this to happen during the latter half
          of October and early November.
        </p>
        <p>
          Once that period has passed, the TRO is sealed, and from there, the
          comments submitted will be considered. Depending on these, it could:
        </p>
        <ul>
          <li>Fail to proceed;</li>
          <li>
            Require further amendments, which would then be reported back to the
            City Environment, South Downs & The Sea Committee who would make a
            final decision;
          </li>
          <li>Proceed as advertised.</li>
        </ul>

        <p>
          If either of the last two resolutions occur, we expect to see the zone
          implemented reasonably quickly thereafter.
        </p>

        <p>
          If support in the past consultation is not sufficient, or concerns are
          raised that the committee deems need further consideration, then a new
          consultation with a new proposal will be published.
        </p>

        <p>
          In most recent conversations, the council appears to remain committed
          to its original timeline (see January update below) despite
          significant delays in the process to date. This would mean that should
          there have been sufficient support in the latest consultation, and
          should the TRO be issued and proceed as advertised, we could still see
          implementation in December.
        </p>
      </Container>

      <Container>
        <h3>
          June 2023:
          <br />A look back a decade...
        </h3>
        <p>
          Hollingbury Crescent, Davey Drive, and Upper Hollingdean Road are the
          first streets within Hollingdean that you arrive on when driving out
          of the adjoining existing parking zones. Hollingdean is surrounded on
          three sides by controlled resident parking, whilst the golf course
          accounts for the remaining borders to the North (with no
          through-roads).
        </p>

        <p>
          Any non-residents looking for free parking will inevitably pass down
          one of these three streets first and stop if space is available. As
          such, these three roads form great examples of areas where residents
          are feeling some of the most substantial pressures in the area.
        </p>

        <p>
          With the power of Google Maps Street View, we can compare the current
          congestion levels for all three roads against how the same streets
          looked a decade ago when there were no adjoining resident parking
          zones. In speaking with residents on these streets, we understand that
          none of the vehicles shown in the June 2023 screenshots (on the right)
          belong to residents of these roads or the local area. Combined with
          the imagery Google Maps has collected, it really helps to demonstrate
          the issues that Hollingdean residents face.
        </p>
        <p>Click on an image to view it at a larger size in a new window.</p>

        <div className='split-images'>
          <a
            href='https://one-hollingdean.com/davey-drive-2014.jpg'
            target='_blank'
            rel='noreferrer'
            className='split-image'
          >
            <img
              src='https://one-hollingdean.com/davey-drive-2014-small.jpg'
              alt='A screenshot from Google Maps showing Davey Drive in Brighton from a decade ago (2012), demonstrating how little traffic and parked vehciles there was at the time.'
            />
          </a>

          <a
            href='https://one-hollingdean.com/davey-drive-2023.jpg'
            target='_blank'
            rel='noreferrer'
            className='split-image'
          >
            <img
              src='https://one-hollingdean.com/davey-drive-2023-small.jpg'
              alt='A screenshot from Google Maps showing Davey Drive in Brighton earlier this year (2023), demonstrating just how congested and busy the area has become due to displaced and non-resident parking.'
            />
          </a>
        </div>

        <div className='split-images'>
          <a
            href='https://one-hollingdean.com/hollingbury-crescent-2014.jpg'
            target='_blank'
            rel='noreferrer'
            className='split-image'
          >
            <img
              src='https://one-hollingdean.com/hollingbury-crescent-2014-small.jpg'
              alt='A screenshot from Google Maps showing Hollingbury Crescent in Brighton from a decade ago (2012), demonstrating how little traffic and parked vehciles there was at the time.'
            />
          </a>

          <a
            href='https://one-hollingdean.com/hollingbury-crescent-2023.jpg'
            target='_blank'
            rel='noreferrer'
            className='split-image'
          >
            <img
              src='https://one-hollingdean.com/hollingbury-crescent-2023-small.jpg'
              alt='A screenshot from Google Maps showing Hollingbury Crescent in Brighton earlier this year (2023), demonstrating just how congested and busy the area has become due to displaced and non-resident parking.'
            />
          </a>
        </div>

        <div className='split-images'>
          <a
            href='https://one-hollingdean.com/upper-hollingdean-road-2014.jpg'
            target='_blank'
            rel='noreferrer'
            className='split-image'
          >
            <img
              src='https://one-hollingdean.com/upper-hollingdean-road-2014-small.jpg'
              alt='A screenshot from Google Maps showing Upper Hollingdean Road in Brighton from a decade ago (2012), demonstrating how little traffic and parked vehciles there was at the time.'
            />
          </a>

          <a
            href='https://one-hollingdean.com/upper-hollingdean-road-2023.jpg'
            target='_blank'
            rel='noreferrer'
            className='split-image'
          >
            <img
              src='https://one-hollingdean.com/upper-hollingdean-road-2023-small.jpg'
              alt='A screenshot from Google Maps showing Upper Hollingdean Road in Brighton earlier this year (2023), demonstrating just how congested and busy the area has become due to displaced and non-resident parking.'
            />
          </a>
        </div>

        <p>
          The same dramatic increase in vehicles, as seen in these screenshots,
          is also occurring across the wider Hollingdean area, especially in the
          areas that directly abut existing parking zones or feed off these
          entry roads.
        </p>

        <p>
          As we have been discussing for many years, continuing to allow
          unrestricted parking in Hollingdean is congruous to not only the
          parking problems that residents have been facing (again - for many
          years) but also to the considerable and unnecessary local traffic,
          noise, and pollution brought from non-resident vehicles.
        </p>

        <p>
          <strong>
            Fundamentally, the parking situation that Hollingdean has
            increasingly found itself in over the past few years means that our
            streets are busier, noisier, smellier, and less safe because
            non-residents use them to park day-to-day and store their vehicles.
          </strong>
        </p>
      </Container>

      <Container>
        <h3>
          May 2023:
          <br />A look at the 'actual' facts and figures
        </h3>
        <p>
          With an apparent mass campaign in full swing to misinform and lie to
          residents of Hollingdean, using fliers and social media posts to
          grossly exaggerate the consequences of a parking scheme, now seems
          like an opportune moment to revisit the results of the initial
          consultation from last year.
        </p>
        <p>
          Following the initial consultation, the results were collated and
          presented to the ET&S Committee in September of 2022. Two sets of
          results were produced, both of which can be found on the council
          committee web page{' '}
          <a
            href='https://democracy.brighton-hove.gov.uk/ieListDocuments.aspx?CId=823&MId=10756&Ver=4'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>
          . We have also downloaded copies of these two files and included them
          in the links below for posterity's sake, although you are welcome to
          confirm that the files here are the same as those published by the
          council.
        </p>
        <h3>1. The complete results</h3>
        <p>
          <strong>
            This is a complete set of results for the entire consultation,
            including every - valid - vote.
            <br />
            <a
              href='https://one-hollingdean.com/2022-initial-consultation-complete-results.pdf'
              target='_blank'
              rel='noreferrer'
            >
              See the full report here.
            </a>
          </strong>
        </p>
        <ul className='results-list'>
          <li>
            <strong>52.6%</strong> supported a residents' parking scheme
          </li>
          <li>
            <strong>45.4%</strong> prefer a full scheme
          </li>
          <li>
            <strong>52.7%</strong> support a Monday-to-Sunday scheme
          </li>
        </ul>
        <p>
          Despite these results very convincingly demonstrating support across
          the district, there were some areas of Hollingdean where support was
          significantly <em>not</em> in favour. This was to be expected as some
          areas within Hollingdean do not struggle with parking. It is therefore
          fair and democratic to take these areas into account.{' '}
          <strong>
            Those areas that did not vote in favour of a parking scheme are not
            to be included in the proposed scheme.
          </strong>{' '}
          However, these areas were consulted again in October last year. The
          turn-out was extremely low and no further action is planned for those
          areas of Hollingdean at this time. More on that is in the January 2023
          post below.
        </p>

        <h3>2. The 'smaller area'</h3>
        <p>
          Considering the split of support across the (roughly) East and West
          areas of Hollingdean, a second set of results was also collated and
          presented{' '}
          <em>
            at the same time, from the same consultation, at the same ES&T
            committee meeting
          </em>
          .
        </p>
        <p>
          <strong>
            These are the results for the area which showed the strongest
            support for a scheme and into which the council proposes controlled
            parking be introduced (at this time).
            <br />
            <a
              href='https://one-hollingdean.com/2022-initial-consultation-smaller-area-results.pdf'
              target='_blank'
              rel='noreferrer'
            >
              See the report for the 'smaller area' here.
            </a>
          </strong>
        </p>
        <ul className='results-list'>
          <li>
            <strong>61.4%</strong> supported a residents' parking scheme
          </li>
          <li>
            <strong>52.3%</strong> prefer a full scheme
          </li>
          <li>
            <strong>60.4%</strong> support a Monday-to-Sunday scheme
          </li>
        </ul>
        <p>
          It is these results which relate directly to the current consultation.
          The residents of the 'smaller area' - which the design covers - voted
          significantly in favour of a full scheme (over a light-touch scheme),
          seven days a week.
        </p>
        <p>
          We are aware that the fliers that have been distributed recently seek
          to confuse people by claiming that the area only voted for a
          light-touch scheme and that the council is pushing forward with a full
          scheme against the will of the residents.{' '}
          <strong>This is not true</strong>.
        </p>
        <p>
          Whilst the wider Hollingdean area did indeed vote very marginally in
          favour of a light-touch scheme rather than a full one, once the areas
          that also voted against a scheme as a whole (and are not being
          included in this design) are removed, the support swings in favour of
          a full scheme instead.
        </p>
        <p>
          <strong>
            Anybody who attempts to argue or convince residents using the 'full'
            set of results rather than the results from the area where the zone
            is being proposed either completely misunderstands the process, or
            is otherwise doing so out of malice
          </strong>
          .
        </p>
      </Container>

      <Container>
        <h3>
          May 2023:
          <br />
          Alarmist fliers make a reappearance in Hollingdean
        </h3>
        <p>
          During the initial consultation last year (and in previous years where
          consultations had been held), fliers started to appear across
          Hollingdean tucked under car windscreen wipers and pushed through
          letterboxes. They were deliberately styled and branded to appear as
          though they had come from us - a formal residential group. This was
          not the case.
        </p>
        <p>
          They quoted inaccurate and alarmist details about the consequences of
          a residential parking scheme here and begged residents to vote against
          the proposals.
        </p>
        <p>
          At the time, residents were able to speak to the individuals
          distributing these fliers and discover that they were not from
          Hollingdean. One was a Seven Dials resident who had been asked by her
          son's school PTA to distribute them; another was a resident from
          Fiveways who admitted to using Hollingdean to park his - multiple -
          vehicles.
        </p>
        <p>
          These are not people who have the interest of Hollingdean residents at
          heart.
        </p>
        <p>
          This week, we have started to see similar fliers appear again,
          although at least this time, <em>some</em> of them are not purporting
          to be from us! However, we did see one from 'One Hollindean' (with a
          missing 'g'). It is unclear if this was deliberate or a typo. The
          quality of the rest of the content on the flier would suggest typo.
        </p>
        <p>
          Of the four different types of leaflet/flier we have now seen this
          time around, we strongly suspect that they all originate from the same
          individuals. Each follows the same tired format: littered with
          spelling, grammatical, and factual errors, over-exaggerated permit
          costs, doom-and-gloom about the consequences of a CPZ, and finally
          urging residents to vote against the proposed plan.
        </p>
        <p>
          The real irony here is that the majority of the vehicles they have
          placed fliers on do not belong to Hollingdean residents and have not
          been touched in between their visits, meaning these leaflets and
          fliers are simply building up the windscreens of the same unmoved,
          non-resident vehicles we would like to see less of.
        </p>
        <p>
          Again, these originate from, and are being distributed by,
          non-residents who stand to lose a lot if they cannot use Hollingdean
          as their personal car park and vehicle storage. Not the residents who
          suffer and struggle with additional pollution, traffic, and congested
          streets as a result.
        </p>
        <p>
          It is important that every resident is able to form and share their
          own opinion in the survey if they should so wish. Please do not be
          fooled by these fliers: the facts they quote are easily demonstratable
          as false, and the people putting them there do not have your best
          interest in mind. Only their own.
        </p>
        <p>
          It is likely no coincidence that these have begun to appear now - as
          we enter the final week of the consultation.
        </p>
        <p>
          If you have not already done so, and feel you would like, please make
          sure you complete the survey on the council website below.{' '}
          <u>The consultation ends on the 4th of June</u>.
        </p>
        <p>
          <strong>
            <a
              href='https://consultations.brighton-hove.gov.uk/parking/hollingdean/'
              target='_blank'
              rel='noreferrer'
            >
              https://consultations.brighton-hove.gov.uk/parking/hollingdean/
            </a>
          </strong>
        </p>
      </Container>

      <Container>
        <h3>
          April 2023:
          <br />
          Full scheme consultation begins
        </h3>
        <div className='image-split'>
          <a
            href='https://one-hollingdean.com/hollingdean-parking-zone-map-design.png'
            target='_blank'
            rel='noreferrer'
            className='split-image'
          >
            <img
              src='https://one-hollingdean.com/hollingdean-parking-zone-map-design-small.png'
              alt='Hollingdean Controlled Parking Zone Map'
            />
          </a>
          <div className='split-content'>
            <p>
              What should now be the final stage of the council's consultations
              regarding parking in Hollingdean has begun and can be accessed and
              filled in (by residents and business owners within the zone) here:
            </p>
            <p>
              <strong>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://consultations.brighton-hove.gov.uk/parking/hollingdean/'
                >
                  https://consultations.brighton-hove.gov.uk/parking/hollingdean/
                </a>
              </strong>
            </p>
            <p>
              This will run until the 4th of June. We now urge all residents and
              business owners within the zone (who will be receiving further
              details from the council in the post in the coming days) to fill
              in the survey to make sure that your opinions and wishes are
              presented to the council.
            </p>
            <p>
              Click on the image to see the proposed plan in greater detail.
              This shows exactly as we expected, with the majority of parking
              within the area being set aside specifically for the use of local
              residents and their visitors. Whilst there are significant amounts
              of shared parking bays included (purple on the map), these are
              limited to either permit holders or paying vehicle owners.
              Non-permit holders will only be able to use these spaces for a
              maximum of four hours and will be unable to return within the
              following four hours.
            </p>
            <p>
              This seems like a fair compromise between ensuring residents
              access to parking near their homes, and the needs of their
              visitors. It also removes the requirement to use limited visitor
              permits when residents have visitors.
            </p>
          </div>
        </div>
      </Container>
      <Container>
        <h3>
          January 2023:
          <br />
          Non-zone consultation results
        </h3>
        <p>
          Following the additional consultation held in October, in which
          Hollingdean residents whose addresses fall outside of the new
          Hollingdean parking permit zone were asked whether they would like to
          also be included, the results have now been published.
        </p>
        <p>
          <strong>
            The results showed a further 52% support for a residents' parking
            scheme.
          </strong>
        </p>
        <p>
          However, the overall turnout for this additional consultation was
          extremely low, with only 11% of the 1,279 households contacted taking
          part. Taking this low turn-out into account, the committee have agreed
          that no change should be made to the Hollingdean detailed design
          consultation boundary. The process continues as before.
        </p>
        <p>
          Also provided in the agenda for January's meeting was an expected
          timeline (published for the first time), which suggests that we can
          expect to see full implementation of the scheme before the end of the
          year. This is a disappointing slip from the original promises made by
          the council, but at least it also now means that the end is in sight
          for our residents.
        </p>
        <img
          src='https://one-hollingdean.com/parking-zone-table.png'
          alt='Timeline of parking zone implementations in Hollingdean and across Brighton'
        />
      </Container>
      <Container>
        <h3>
          October 2022:
          <br />
          Non-zone residents are to be consulted
        </h3>
        <p>
          The council is now reaching out to those Hollingdean residents who
          live in roads that voted 'no' during the original consultation. As we
          have seen in Hollingdean when neighbouring zones have been introduced
          (and in particular Zone G on Hollingbury Road), there is a concern
          around displacement parking.
        </p>
        <p>
          Those streets without parking controls are very likely to find that
          individuals who had previously used the wider Hollingdean area to park
          their second and third cars, use as part of their commute into
          Brighton, or store their camper cans, will instead park on those
          remaining 'free' roads. This will have the effect of compressing those
          vehicles into a smaller area, making parking for those residents more
          difficult still.
        </p>
        <p>
          As a second stage to our parking consultation, residents on these
          roads within Hollingdean will be receiving a letter explaining these
          circumstances, and asking for their opinion on whether they would like
          their road to be included in the final design consultation.
        </p>
        <p>
          This stage of the consultation starts on the 28th of October and is
          only available to residents living on roads within Hollingdean not
          listed in our previous update (below). Share your thoughts with the
          council on the link below (from the 28th, and before the 20th of
          November):
        </p>
        <p>
          <a
            href='https://consultations.brighton-hove.gov.uk/parking/hollingdean-parking/'
            target='_blank'
            rel='noreferrer'
          >
            https://consultations.brighton-hove.gov.uk/parking/hollingdean-parking/
          </a>
        </p>
        <p>
          If the results of this show any significant change in opinion, these
          will be reported back to the Environment, Transport and Sustainability
          Committee in January, to seek approval for those additional roads to
          be included.
        </p>
        <p>
          Further into the future, we expect that the design consultation stage
          will occur in the Spring of next year, with the finalised zone
          confirmed and implemented in time for the summer.
        </p>
      </Container>
      <Container>
        <h3>
          September 2022:
          <br />
          The Majority of Hollingdean residents vote <u>for</u> parking controls
        </h3>
        <div className='image-split'>
          <a
            href='https://one-hollingdean.com/hollingdean-parking-zone-map.png'
            target='_blank'
            rel='noreferrer'
            className='split-image'
          >
            <img
              src='https://one-hollingdean.com/hollingdean-parking-zone-map-small.png'
              alt='Hollingdean Controlled Parking Zone Map'
            />
          </a>
          <div className='split-content'>
            <p>
              Long anticipated, the results of the recent parking consultation
              here in Hollingdean were published by the council earlier this
              month.
            </p>
            <p>
              <strong>
                As predicted,{' '}
                <u>
                  more than half of Hollingdean residents voted in favour of
                  controlled parking
                </u>{' '}
                within our neighbourhoods.
              </strong>
            </p>
            <p>
              This is in direct contradiction to the{' '}
              <a
                href='https://www.brightonandhovenews.org/2022/09/23/hollingdean-residents-reject-parking-controls/'
                target='_blank'
                rel='noreferrer'
              >
                misleading article published by Felice Southwell for Brighton &
                Hove News
              </a>
              , who (wrongly) claims that residents had rejected parking
              controls.
            </p>
            <p>
              <strong>
                Also as predicted, there were significant differences in the
                level of support across different areas of our ward. Areas to
                the South and West were particularly supportive (with many roads
                showing support in excess of 80%), whilst areas towards the East
                voted more modestly.
              </strong>
            </p>

            <p>
              Taking this into account, it has been proposed (and is now being
              actioned by the council), that they focus on the introduction of
              controlled parking in the area of Hollingdean where support was
              highest.
            </p>
            <p>
              <strong>
                This will be a full scheme, operating from 9am to 8pm, seven
                days a week.
              </strong>
            </p>
            <p>
              This is being actioned on the condition (as we requested) that
              those residents of Hollingdean currently falling outside of the
              new zone, be written to by the council and given a further
              opportunity to join the zone should they wish; and that this
              should happen as soon as is possible after the zone is
              implemented.
            </p>
            <p>
              The map included here shows the layout of this new zone, which
              will encompass the following streets within Hollingdean:
            </p>
            <ol>
              <li>Adams Close</li>
              <li>Barnett Road</li>
              <li>Davey Drive</li>
              <li>Dudley Road</li>
              <li>Dunster Close</li>
              <li>Freehold Terrace</li>
              <li>Harrington Place</li>
              <li>Hertford Road</li>
              <li>Hollingbury Crescent</li>
              <li>Hollingbury Place</li>
              <li>Hollingbury Rise</li>
              <li>Hollingbury Rise West</li>
              <li>Hollingdean Road</li>
              <li>Hollingdean Terrace</li>
              <li>Horton Road</li>
              <li>Major Close</li>
              <li>Payne Terrace</li>
              <li>Quarry Bank Road</li>
              <li>Roedale Road</li>
              <li>Southmount</li>
              <li>Stanmer Park Road</li>
              <li>Stanmer Villas</li>
              <li>The Crossway</li>
              <li>The Linkway</li>
              <li>Tintern Close</li>
              <li>Upper Hollingdean Road</li>
              <li>Waverley Crescent</li>
              <li>Wigmore Close</li>
            </ol>
            <p>
              <strong>If you are a resident of one of these roads:</strong> you
              will be in the new parking zone.
              <br />
              The council will hold a second consultation shortly during which
              they will write to you to present the zone design and offer an
              opportunity to feed back.
            </p>

            <p>
              <strong>
                If you are a Hollingdean resident but <i>not</i> of one of these
                roads:
              </strong>{' '}
              you will <i>not </i> be in the new parking zone.
              <br />
              It is unlikely that you will be further involved in the
              consultation process this time around. You will, however, be
              written to by the council in due course to explain your options
              and (potentially) offer the opportunity of a second consultation
              to include your roads.
            </p>
            <p>
              We are pleased to see that council workers have already been
              spotted surveying the new zone. We look forward to more
              information before Christmas.
            </p>
          </div>
        </div>
      </Container>
      <Container>
        <h3>June 2022</h3>
        <p>
          With the latest parking consultation for Hollingdean now complete we
          reached out to council officials in the 'Parking Design &
          Implementation' team for comment. Although a significant amount of
          analysis is yet to be undertaken, and no official results will be
          published for some time, they were able to comment that results
          appeared to be '<u>significantly in favour</u>' of a scheme within
          Hollingdean.
        </p>
        <p>
          This means that work to design a suitable scheme has begun, which will
          be presented and considered at the next Environment, Transport and
          Sustainability Committee on 20th September. Full results will be
          available on{' '}
          <a href='https://democracy.brighton-hove.gov.uk/ieListMeetings.aspx?CommitteeId=823'>
            the council committee page
          </a>{' '}
          approximately a week prior to the committee meeting.
        </p>
        <p>
          Whilst it is not possible to draw further conclusions at this time, it
          seems extremely likely that we may see (at least in part) the
          introduction of controlled resident parking in the following months.
        </p>
        <p>
          It is important to remember that Hollingdean is a large and diverse
          ward within Brighton. Parking has been a contentious issue here for
          many years; areas of Hollingdean that border existing parking control
          schemes in Fiveways and Lewes Road in particular have been outspoken
          on the issue for many years. Conversely, areas of Hollingdean further
          North and East (and in particular in the estate itself) have seen very
          few problems.
        </p>
        <p>
          This has historically led to a significant split in residents who are
          desperate for, and who are firmly against, controlled parking within
          their area.
        </p>
        <p>
          We expect the council to treat the results of this consultation on a
          more granular level than seen previously. Rather than introducing (or
          not introducing) permits across the ward, this would potentially mean
          new schemes on streets or local areas where support is highest (mainly
          to the South of Hollingbury Place and to the West of Hollingdean
          Terrace), and perhaps not where support has proven to be lower.
        </p>
        <p>
          We feel that this would be a fair solution to a democratic process.
          However, it would also likely mean further displacement of
          non-resident parking into areas of Hollingdean (and surrounding) where
          parking remained uncontrolled.
        </p>
        <p>We wait to hear more from the council in the coming weeks.</p>
      </Container>
    </Layout>
  );
};

export default IndexPage;
